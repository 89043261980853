<template>
  <div>
    <div :id="main" style=" text-align: center;width: 4.5rem; height: 4.3rem; margin-top: -0.5rem"></div>
  </div>
</template>

<script lang="text/javaScript">
import * as echarts from 'echarts'
import { defineComponent, nextTick, onBeforeUnmount, onMounted } from 'vue'
export default defineComponent({
  components: {},
  props: {
    date: {
      type: Array,
    },
    data: {
      type: Array,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    main: {
      type: String
    }
  },
  setup(props) {
    let myChart = null
    onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
    onMounted(() => {
      nextTick(() => {
        // 获取DOM元素并且进行初始化
        myChart = echarts.init(document.getElementById(props.main))
        // 创建图标
        myChart.setOption({
          backgroundColor:'rgba(128, 128, 128, 0.1)',
          title: {
            text: '',
          },
          tooltip: {
            trigger: 'axis',
            position: function (pt) {
              return [pt[0], '10%']
            },
          },
          grid: {
            // height: '65%',
            x:45,
            y:20,
            x2:25,
            y2:30
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: props.date,
            triggerEvent: true,
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: true,
              interval: Math.ceil(props.date.length - 2),
              formatter: function (value) {
                console.log('value=', value)
                if (value !== undefined && value !== null) {
                  if (value.length > 10) {
                    return `${value.slice(5, 10)}`
                  }
                }

                return value
              },
            },
          },
          yAxis: {
            type: 'value',
            min: props.min,
            max: props.max,
          },
          series: [
            {
              name: '',
              type: 'line',
              symbol: 'none',
              sampling: 'lttb',
              itemStyle: {
                color: '#e40012',
              },
              data: props.data,
            },
          ],
        })
      })
    })

    return {}
  },
})
</script>

<style scoped lang="scss"></style>
